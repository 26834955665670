<template>
  <section class="page">
    <div>
      <div class="response">
        <div class="response__title">Места в общей очереди закончились!</div>

        <div
          :class="
            $route.params.hasTimeslot ? 'response__text' : 'response__subtitle'
          "
        >
          К сожалению суточный лимит общей очереди на сегодня израсходован, он
          обновится завтра в 00:01. Вы можете повторить попытку после обновления
          лимита.
        </div>

        <div v-if="!$route.params.hasTimeslot" class="response__text">
          Также вы можете забронировать Таймслот (место в электронной очереди)
          на сайте zernovozam.ru или в мобильном приложении "Зерновозы".
        </div>
      </div>
      <MainButton
        title="Понятно"
        type="primary"
        size="big"
        width="full"
        class="confirm-button"
        @button-clicked="$router.push({ name: 'terminal-carNumber' })"
      />
    </div>

    <TechSupport />
  </section>
</template>

<script>
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import TechSupport from '@/views/terminalUI/components/TechSupport'
import finallyPagesMixin from '@/views/terminalUI/mixins/finallyPagesMixin'
export default {
  name: 'LimitHandResponse',
  components: {
    MainButton,
    TechSupport,
  },
  mixins: [finallyPagesMixin],
}
</script>

<style lang="sass" scoped>
.page
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 75px 9% 60px

  .response
    background: $color-white
    border-radius: 40px
    padding: 40px
    margin-bottom: 60px
    color: $mainBlack
    &__title
      display: block
      width: 790px
      margin-bottom: 32px
      font-family: $fontPrimary
      font-size: 40px
      font-weight: 500
      line-height: 48px
    &__subtitle, &__text
      font-family: $fontPrimary
      font-size: 24px
      font-weight: 500
      line-height: 30px
    &__subtitle
      margin-bottom: 32px

  .confirm-button
    margin: 0 auto
</style>
