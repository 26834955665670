var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page" },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "response" }, [
            _c("div", { staticClass: "response__title" }, [
              _vm._v("Места в общей очереди закончились!"),
            ]),
            _c(
              "div",
              {
                class: _vm.$route.params.hasTimeslot
                  ? "response__text"
                  : "response__subtitle",
              },
              [
                _vm._v(
                  " К сожалению суточный лимит общей очереди на сегодня израсходован, он обновится завтра в 00:01. Вы можете повторить попытку после обновления лимита. "
                ),
              ]
            ),
            !_vm.$route.params.hasTimeslot
              ? _c("div", { staticClass: "response__text" }, [
                  _vm._v(
                    ' Также вы можете забронировать Таймслот (место в электронной очереди) на сайте zernovozam.ru или в мобильном приложении "Зерновозы". '
                  ),
                ])
              : _vm._e(),
          ]),
          _c("MainButton", {
            staticClass: "confirm-button",
            attrs: {
              title: "Понятно",
              type: "primary",
              size: "big",
              width: "full",
            },
            on: {
              "button-clicked": function ($event) {
                return _vm.$router.push({ name: "terminal-carNumber" })
              },
            },
          }),
        ],
        1
      ),
      _c("TechSupport"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }